import './footer.css';
import { Link, useLocation } from 'react-router-dom';


function FooterComponent() {

    const location = useLocation();

    return (

        <footer className={`w-100 ${(location.pathname==='/' || location.pathname === '/dashboard/') ? 'black-footer' : ''}`}>
            <div className='footer-wrapper'>
            <div className='row g-0'>
                <div className='col-sm-12 mb-sm-4 col-xl'>
                    <img className='logo' src={`${process.env.PUBLIC_URL}/giappichelli-logo-w.png`} alt="Logo Giappichelli" />
                    <div className='social'>
                        <a href="https://www.linkedin.com/company/g--giappichelli-editore-s-r-l-/posts/?feedView=all" target="_blank">
                            <img src={`${process.env.PUBLIC_URL}/linkedin.png`} alt="Linkedin Giappichelli" /></a>
                        <a href="https://www.instagram.com/g_giappichelli_editore/" target="_blank">
                            <img src={`${process.env.PUBLIC_URL}/instagram.png`} alt="Instagram Giappichelli" /></a>
                        <a href="https://www.facebook.com/Giappichelli.Editore" target="_blank">
                            <img src={`${process.env.PUBLIC_URL}/facebook.png`} alt="Facebook Giappichelli" /></a>
                        <a href="https://www.youtube.com/@GiappichelliIt_Editore" target="_blank">
                            <img src={`${process.env.PUBLIC_URL}/youtube.png`} alt="YouTube Giappichelli" /></a>
                    </div>
                </div>
                <div className='col-6 col-lg-3 col-xl-2 mb-sm-4'>
                    <h1>Giappichelli</h1>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/chi-siamo`}>Chi siamo</a>
                    {/*<a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/`}>Accessibilità</a>*/}
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/blog`}>Eventi</a>
                </div>
                <div className='col-6 col-lg-3 col-xl-2 mb-sm-4'>
                    <h1>Esplora</h1>
                    {/*<a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/`}>Università</a>*/}
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/professionisti-home`}>Professionali</a>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/docenti-home`}>Docenti</a>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/studenti-home`}>Studenti</a>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/biblioteche-home`}>Enti e Biblioteche</a>
                </div>
                <div className='col-6 col-lg-3 col-xl-2 mb-sm-4'>
                    <h1>Prodotti</h1>
                    {/*<a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/`}>Catalogo libri e eBook</a>*/}
                    {/* <a href={`${process.env.REACT_APP_PREFIX_MOODLECORSI}/`}>Corsi di formazione</a> */}
                    <a href="https://corsi.giappichelli.it">Corsi di formazione</a>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/riviste`}>Riviste scientifiche</a>
                    <Link to="/landingpage/edu/" onClick={() => window.scrollTo(0, 0)}>Giappichelli EDU</Link>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/biblioteche-home`}>Biblioteca digitale</a>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/bookshelf/serie`}>Collane</a>
                </div>
                <div className='col-6 col-lg-3 col-xl-2 mb-sm-4'>
                    <h1>Servizio Clienti</h1>
                    <Link to="/help-center/" onClick={() => window.scrollTo(0, 0)}>FAQ</Link>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/contattaci`}>Assistenza</a>
                    {/*<a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/`}>Adotta un volume</a>*/}
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/pubblicare-con-noi`}>Pubblica con noi</a>
                    {/*<a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/`}>Spedizioni e resi</a>*/}
                </div>
            </div>
            <div className='row g-0'>
                <div className='col-md'>
                    <p>© Giappichelli Editore 2024</p>
                </div>
                <div className='col-md terms'>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/condizioni-di-vendita`}>Termini e Condizioni</a>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/privacy`}>Privacy</a>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/privacy/cookies`}>Cookie</a>
                </div>
            </div>
            <hr />
            <div className='row g-0'>
                <div className='col'>
                    <span>Copyright © 2022 managed by Blix.it & Ne.Ws | G. Giappichelli Editore srl - Via Po 21 ang. Via Vasco 2 - 10124 Torino Iscriz. Ufficio Registro di Torino, P.I e C.F 02874520014 — Cod. univoco J6URRTW — Capitale sociale i. v. € 46.800,00</span>
                </div>
            </div>
            </div>
        </footer>

    )

}

export default FooterComponent;
