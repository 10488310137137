import './updated.css';
import { Link } from 'react-router-dom';

function PasswordUpdated () {

    return(

        <div className='page updated'>
            <div className='row h-100 g-0'>
                <div className='col-6 page-background'></div>
                <div className='col-sm-5 col-md-6 col-lg-5 col-xl-6 page-right'>
                    <h1>Password aggiornata</h1>
                    <p>La tua password è stata aggiornata correttamente.<br />
                    Ora puoi accedere ai servizi Giappichelli con le tue nuove credenziali</p>
                    <form>
                        <div>
                            <button className='gradient-button'><Link to="/login/">Accedi</Link></button>
                        </div>
                    </form>
                 </div>
            </div>
        </div>

    )

}

export default PasswordUpdated;
