import './landing.css';
import contentData from './ContentActivate';

function ActivateLanding({ landingPage }) {

    const content = contentData[landingPage] || contentData.default;
   
    return (
        <section className='activate-landing'>
            <div className="activate-content">
                <div className='activate-left'>
                <img src={`${process.env.PUBLIC_URL}${content.img}`} alt={content.buttonText} />
                </div>
                <div className='activate-right'>
                    <h1>{content.title}</h1>
                    <h2>{content.subtitle}</h2>
                    <p>{content.text}</p>
                    <hr />
                    <a href={content.buttonHref}>
                        <button className='gradient-button'>{content.buttonText}  <img src={`${process.env.PUBLIC_URL}/arrow-w.svg`} alt={content.buttonText} /></button>
                    </a>
                </div>
            </div>
        </section>
    )
}


export default ActivateLanding;
