import './login.css';
//import { Link } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { useNavigate,useLocation } from 'react-router-dom';
import { useEffect,useState,useRef } from 'react';
import queryString from 'query-string';

function LoginComponent () {
    const auth = useAuth();
    let navigate = useNavigate();
    const location = useLocation();
    const { redirect } = queryString.parse(location.search)
    const [isredirecting,setRedirecting]=useState(false);
    const effectRan = useRef(false);// use to avoid in dev mode double rendering after redirect, causing NS_BINDING_ERR: 
    useEffect(() => {
      if(auth.isAuthenticated){
        navigate('/');
      }else{
        if((!isredirecting) && (!effectRan.current)){
          setRedirecting(true);
          auth.signinRedirect({state:{url:redirect}});
	  return () => effectRan.current = true;
        }
      }
    },[auth,navigate,location,isredirecting,redirect]);
    return (<></>);
    /*return(

        <div className='page'>
            <div className='row h-100 g-0'>
                <div className='col-6 page-background'></div>
                <div className='col-6 page-right'>
                    <h1>Accesso</h1>
                    <p>Nuovo su Giappichelli, <Link to="/register/">crea il tuo account</Link></p>
                    <div className='google-access'>
                        <img src={`${process.env.PUBLIC_URL}/google-access.svg`} alt="Accedi con Google" />
                        <span>Accedi con Google</span>
                    </div>
                    <div className='divider'><h3>Oppure</h3></div>
                    <form>
                        <div>
                            <label for="email" className='form-label'>Email *</label>
                            <input type='email' id="email" placeholder='Email' />
                        </div>
                        <div>
                            <label for="password" className='form-label'>Password *</label>
                            <input type='password' id="password" placeholder='Password' />
                        </div>
                        <div>
                            <p className='text-end'><Link to="/forgot-password/">password dimenticata?</Link></p>
                        </div>
                        <div>
                            <button>Accedi</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )*/

}

export default LoginComponent;
