import './landing.css';
import contentData from './ContentCollab';

function CollaborateLanding({ landingPage }) {

    const content = contentData[landingPage] || contentData.default;
   
    return (
        <section className='content-landing'>
            <div className="collaborate-landing d-flex">
                <h1>{content.h1}</h1>
                <div className={`collaborate-cards ${content.gridClass}`}>
                    {content.cards.map((card, index) => (
                    <div className='card-info' key={index}>
                        <div className='card-content'>
                            <h5>{card.h5}</h5>
                            <p>{card.p}</p>
                            <hr />
                            <a target='_blank' href={card.link}>{card.linkText}</a>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </section>
    )
}


export default CollaborateLanding;
