import Accordion from 'react-bootstrap/Accordion';
import './helpcenter.css';
import { Link } from 'react-router-dom';
import PrevPage from '../utilities/prevPage';

const HelpCenter = () => {

    return (
        <div className="account-container">
            <div className="account-top">
                <div className="row h-100 align-items-center">
                    <PrevPage />
                    <div className="col-10 text-center">
                        <h1>Help Center</h1>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
            <div className="account-body">
                <h2>Domande frequenti</h2>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Ho problemi con l'accesso</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Se hai <b>dimenticato la tua password</b> <Link to="/forgot-password">clicca qui</Link>.<br/>
                                In caso non ti arrivi la mail di recupero password entro pochi minuti puoi:<br/>
                                1. controllare che non sia nello spam<br/>
                                2. riprovare la procedura del recupero password, avendo cura di verificare l'indirizzo mail inserito<br/>
                                3. contattare il <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/contattaci`}>servizio clienti</a><br/>
                            </p>
                            {/* <p>
                                Se <b>non ricordi l'indirizzo mail</b> con cui ti sei registrato, prova a cercare nelle tue caselle una messaggio di conferma di registrazione da @giappichelli.it.
                            </p> */}
                            <p>
                                Se <b>non utilizzi più l'indirizzo mail con cui ti sei registrato</b>, contatta il nostro <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/contattaci`}>servizio clienti</a> avendo cura di inserire il tuo nome e cognome registrati in piattaforma.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Non riesco ad accedere utilizzando la Google Login</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Se hai effettuato la registrazione inserendo i tuoi dati, email e password, non puoi accedere utilizzando Google, dovrai sempre procedere alla login con email e password. Se hai dimenticato la tua password <Link to="/forgot-password">clicca qui</Link>.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Ho problemi con il codice di attivazione prodotto</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Per attivare una risorsa utilizzando un codice devi essere registrato e aver effettuato l'accesso alla piattaforma <Link to="#">Myhome</Link>.
                                Una volta entrato, clicca in alto a destra su <a href="?show=activateProduct">attiva prodotto</a>.
                            </p>
                            <p>
                                Inserisci il codice seguendo le istruzioni, se hai un libro, lo troverai nella prima pagina interna.
                                Quando il codice inserito appare in verde è valido e potete confermare l'attivazione del nuovo prodotto.
                                Se il codice appare in rosso verifica attentamente il corretto inserimento dei caratteri.
                            </p>
                            <p>
                                Nel caso il problema persista potete contattare il <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/contattaci`}>servizio clienti</a> segnalando le tue credenziali di registrazione, il codice non funzionante e l'ISBN del libro (codice sul retro del volume).
                            </p>
                            <p>
                                Attenzione: il codice funziona una volta sola e non può essere trasferito ad un altro account.
                                L'inserimento di un codice attiva immediatamente la risorsa, avviando la decorrenza del suo periodo di accessibilità.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Che cos'è un'eBook Giappichelli?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Un eBook di Giappichelli è una versione digitale del tuo libro di testo facile da usare, che include strumenti di studio avanzati per aiutarti a imparare nel modo migliore.
                                Usa la ricerca migliorata per trovare ciò di cui hai bisogno all'interno del tuo eBook, evidenzia e prendi appunti per segnare le informazioni importanti, usa i quiz per mettere alla prova le tue conoscenze e utilizza l'audio per ascoltare il testo.
                                Ogni funzione è progettata per aiutarti a imparare in modo più efficiente e ottenere risultati.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Dove trovo i miei eBook?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Quando acquisti un eBook, puoi accedervi dalla piattaforma <Link to="/dashboard/">Myhome</Link> del tuo account Giappichelli.
                                I prodotti acquistati sono visibili nella sezione I miei prodotti.
                                Per l'accesso è necessaria una connessione web.

                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Che cosa ho bisogno per accedere agli eBook?</Accordion.Header>
                        <Accordion.Body>
                                <p /* style={{"color":"red"}} */>
                                    Puoi utilizzare il tuo eBook da qualsiasi PC con sistemi operativi Microsoft® Windows e macOS (OS X), oppure da uno smartphone Android o iOS. E' richiesta la connessione ad internet. L'eReader è supportato da Chrome, Edge, Firefox e Safari.
                                </p>
                                <p /* style={{"color":"red"}} */>
                                    Microsoft® Windows 10<br/>
                                    Consigliato: Chrome > 119, Chrome Mobile > 128, Firefox > 121<br/>
                                    Supportati anche: Edge > 119<br/>
                                </p>
                                <p /* style={{"color":"red"}} */>
                                    macOS (OS X) 10.13, 10.14, 10.15<br/>
                                    Consigliato: Safari > 17.4<br/>
                                    Supportati anche: Google Chrome > 119, Firefox > 121
                                </p>
                                <p /* style={{"color":"red"}} */>
                                    Android<br/>
                                    Consigliato: Chrome Mobile > 128<br/>
                                </p>
                                <p /* style={{"color":"red"}} */>
                                    iOS<br/>
                                    Consigliato: Safari Mobile > 17.4<br/>
                                </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Non trovo i miei prodotti digitali all'interno del mio account sulla piattaforma Myhome</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Verifica di aver effettuato l'accesso a <Link to="/dashboard/">Myhome</Link> utilizzando la stessa email che hai utilizzato per acquistare i tuoi prodotti sull’e-commerce giappichelli.it.
                                Troverai tutti i tuoi acquisti sia in pagina nella sezione <i>I tuoi prodotti</i>, sia da menù cliccando su <i>I miei acquisti</i>.<br/>
                                Nel caso il problema persista contatta il nostro <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/contattaci`}>servizio clienti</a> segnalando il numero d'ordine o il codice di attivazione del prodotto mancante.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>Acquista e accedi ai corsi di formazione</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                I corsi di formazione che acquisti sono visibili sulla piattaforma <Link to="/dashboard/">Myhome</Link>, da lì puoi accedervi direttamente per partecipare alle lezioni e accedere a tutte le risorse didattiche.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="8">
                        <Accordion.Header>Hai bisogno di un rimborso relativo a un acquisto online?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Contatta il <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/contattaci`}>servizio clienti</a> comunicando il tuo codice d’ordine e le ragioni della tua richiesta.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>Cos'è Giappichelli EDU?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Giappichelli EDU è la piattaforma digitale di studio di prossima generazione in cui puoi trovare diversi strumenti e contenuti di qualità per migliorare la tua preparazione e comprensione del diritto:
                                casi studio, quiz di autovalutazione, mappe concettuali e molto altro.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header>Dove trovo le ricevute di acquisto?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Per ogni acquisto ti abbiamo inviato una email di conferma ordine con la ricevuta dei tuoi acquisti.
                                Nella pagina <Link to="/my-products/">tuoi acquisti</Link> puoi visualizzare lo storico dei tuoi acquisti.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* <h2 className='mb-0'>Assistenza clienti</h2>
                <div className='row customer-care'>
                    <div className='col-6'>
                        <h3>Titolo</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla congue iaculis urna, vel vehicula augue commodo sed. Phasellus in libero condimentum felis consectetur commodo. Ut id rhoncus nisi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        <hr />
                        <a href="">Link a qualcosa <img src={`${process.env.PUBLIC_URL}/arrow-link.svg`} alt="leggi tutto" /></a>
                    </div>
                    <div className='col-6'>
                        <h3>Titolo</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla congue iaculis urna, vel vehicula augue commodo sed. Phasellus in libero condimentum felis consectetur commodo. Ut id rhoncus nisi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        <hr />
                        <a href="">Link a qualcosa  <img src={`${process.env.PUBLIC_URL}/arrow-link.svg`} alt="leggi tutto" /></a>
                    </div>
                </div> */}
            </div>
        </div>
    );

}

export default HelpCenter;
