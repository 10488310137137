import './landing.css';
import contentData from './ContentNews.json';

function NewsLanding({ landingPage }) {
    const content = contentData[landingPage] || contentData.default;

    return (
        <section className='content-landing'>
            <h1>{content.h1}</h1>
            <h4>{content.h4}</h4>
            <div className={`d-flex landing-news row g-0 ${content.gridClass}`}>
                {content.cards.map((card, index) => (
                    <div className="col" key={index}>
                        <div className="news-image">
                            <img src={`${process.env.PUBLIC_URL}${card.img}`} alt={card.h5} />
                        </div>
                        <div className='card-content'>
                            <h5>{card.h5}</h5>
                            <p>{card.p}</p>
                            <hr />
                            <a target='_blank' href={card.link}>{card.linkText}</a>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default NewsLanding;
