import SelectedContentBox from './selectedContent';
import CardRivista from './CardRivista';
import { useEffect, useState,useContext/*,useRef*/ } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../../AppContext';
import {uscall/*,getfirst*/} from '../../../us';
//import UserContext from '../../../UserContext';
import CommonContext from '../../../CommonContext';

export default function RivisteForYou(){
    const auth = useAuth();
    const appcontext = useContext(AppContext);
    //const usercontext = useContext(UserContext);
        const commoncontext = useContext(CommonContext);

	const [producttype_riviste,setProductTypeRiviste]=useState(null);
    const [products,setProducts]=useState(null);
	const now=(new Date()).valueOf();
    useEffect(() => {
	const cb=async function(){
		let bs=[];
		for(let i in commoncontext.producttypes){
			if(['rivista'].includes(commoncontext.producttypes[i].code))bs.push(commoncontext.producttypes[i].uuid);
		}
		setProductTypeRiviste(bs);
	}
	if((!producttype_riviste) && commoncontext && commoncontext.producttypes)
		cb();
    },[auth,appcontext,commoncontext,producttype_riviste]);

    useEffect(() => {
	const cb=async function(){
		//let filter={func:'and',fields:[{field:'type',func:'in',value:producttype_riviste},{field:'available',func:'is',value:true},{field:'pubdate',func:'gte',value:now}]};
		//const ps=await uscall(appcontext,auth,'ecommerce',null,'product_search',null,{filter:filter,limit:20,sort:{field:'rivista_position',desc:false}});
		const ps=await uscall(appcontext,auth,'ecommerce',null,'magento_getriviste',null,{limit:20});//TODO: filtro solo libri per studenti
		if(typeof(ps['error'])!='undefined')return;
		setProducts(ps.result);
	}
	if(products);else if(producttype_riviste) cb();
    },[auth,appcontext,/*usercontext,*/products,now,producttype_riviste]);

	return (<>
			{products ?(
				<>{products.length>0?(
		<SelectedContentBox title='Riviste per te' allurl={`${process.env.REACT_APP_PREFIX_MAGENTO}/riviste`}>
				{products.map(product=>(
					<CardRivista key={product.uuid?product.uuid:product.magento_product_id} title={product.name} frequency={product.periodicita} url={product.magento_custom && product.magento_custom.linkrivista?product.magento_custom.linkrivista:product.url} imageurl={product?.magento_custom?.image} />
				))}
		</SelectedContentBox>
				):(
					<></>
				)}</>
			):(
		<SelectedContentBox title='Riviste per te' allurl={`${process.env.REACT_APP_PREFIX_MAGENTO}/riviste`}>
					<CardRivista key="fake1" />
					<CardRivista key="fake2" />
					<CardRivista key="fake3" />
					<CardRivista key="fake4" />
					<CardRivista key="fake5" />
		</SelectedContentBox>
			)}
	</>);
};

