import './landing.css';

function CardsLanding() {
   
    return (
        <section className='content-landing'>
            <h1>Le ultime pubblicazioni</h1>
            <div className="d-flex landing-cards">
                <div>
                    <a href='https://stage.giappichelli.it/manuale-del-processo-familiare-e-minorile-9791221106824' target='_blank'>
                    <img src={`${process.env.PUBLIC_URL}/landing/manuale-processo-familiare-minorile.png`} alt="Cover" />
                    <button className="chip"><span>Novità</span></button>
                    <div className="pricetag"><span>€ 32,00</span></div>
                    </a>
                </div>
                <div>
                    <a href='https://stage.giappichelli.it/diritto-internazionale-dei-diritti-umani-9791221102673' target='_blank'>
                    <img src={`${process.env.PUBLIC_URL}/landing/diritto-internazionale-diritti-umani.png`} alt="Cover" />
                    <button className="chip"><span>Novità</span></button>
                    <div className="pricetag"><span>€ 38,00</span></div>
                    </a>
                </div>
                <div>
                    <a href='https://stage.giappichelli.it/diritto-privato-9788892143531' target='_blank'>
                    <img src={`${process.env.PUBLIC_URL}/landing/diritto-privato.png`} alt="Cover" />
                    <button className="chip"><span>Novità</span></button>
                    <div className="pricetag"><span>€ 69,00</span></div>
                    </a>
                </div>
                <div>
                    <a href='https://stage.giappichelli.it/manuale-di-diritto-commerciale-9788892143791' target='_blank'>
                    <img src={`${process.env.PUBLIC_URL}/landing/manuale-diritto-commerciale.png`} alt="Cover" />
                    <button className="chip"><span>Novità</span></button>
                    <div className="pricetag"><span>€ 54,00</span></div>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default CardsLanding;
