import { Link } from 'react-router-dom';
import './landing.css';
import Accordion from 'react-bootstrap/Accordion';
import { useEffect,useContext,useState/*,useRef*/ } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../AppContext';
import UserContext from '../../UserContext';


export default function LandingEdu() {
    const auth = useAuth();
    const appcontext = useContext(AppContext);
    const usercontext = useContext(UserContext);
	const [curuser,setCurUser]=useState(null);
	const [hasedu,setHasEdu]=useState(false);
    useEffect(() => {
	const checkUser=async function(){
		const user=usercontext.user;
		setCurUser(user);
	};
	if(usercontext && usercontext.user)
		checkUser();
    }, [appcontext,auth,usercontext]);
    useEffect(() => {
		if(curuser){
			if(curuser.has_edu_upto && curuser.has_edu_upto>(new Date()).valueOf())setHasEdu(true);
		}
	},[curuser]);


	return (
            <section className="landing-edu">
                  <div className='edu-top'>
                        <dvi className="top-headings">
                              <h1>Un abbonamento semestrale a migliaia di risorse didattiche per passare i tuoi esami di giurisprudenza.</h1>
                              <h2>Approfitta subito della promo -50% <span>(€9,00/mese)</span></h2>
                        </dvi>
                  </div>
                  <div className='edu-cards'>
                        <div className='card'>
                              <div className='card-content'>
                                    <div className='content-top'>
                                          <div className='top-info'></div>
                                          <div className='bottom-info'>
                                                <div className='promo'>
                                                      <div className='promo-headings'>
                                                            <h2>EDU</h2>
                                                            <span>per 6 mesi</span>
                                                      </div>
                                                      <div className='promo-chip'>
                                                            <span>Promo -50%</span>
                                                      </div>
                                                </div>
                                                <div className='pricing'>
                                                      <h2>€ 4,50 /mese</h2>
                                                      <h3>Pagamento mensile, dopo 6 mesi cancelli quando vuoi</h3>
                                                </div>
                                                <div className='action'>
                                                      {hasedu?(<Link to='/edu' className='gradient-button align-self-center button-disabled'>Abbonati ora <img src={`${process.env.PUBLIC_URL}/arrow-w.svg`} /></Link>):(<Link to='/create-checkout/abbonamentoedu' className='gradient-button align-self-center'>Abbonati ora <img src={`${process.env.PUBLIC_URL}/arrow-w.svg`} /></Link>)}
                                                </div>
                                                <div className='list'>
                                                      <h4>Include:</h4>
                                                      <div className='list-items'>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <span className='bold'>Accesso illimitato a tutte le risorse didattiche EDU</span>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <div>
                                                                        <span className='bold'>Preparazione agli esami del 1° e 2° anno di giurisprudenza: </span>
                                                                        <span>diritto pubblico, diritto privato, diritto penale, diritto del lavoro, diritto amministrativo</span>
                                                                  </div>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <div>
                                                                        <span className='bold'>Migliaia quiz </span>
                                                                        <span>di ripasso e test di autovalutazione</span>
                                                                  </div>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <span className='bold'>Mappe concettuali delle materie</span>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className='card disabled-card'>
                              <div className='overlay'></div>
                              <div className='card-content'>
                                    <div className='content-top'>
                                          <div className='top-info'>
                                                <div className='info-chip'>
                                                      Coming Soon
                                                </div>
                                          </div>
                                          <div className='bottom-info'>
                                                <div className='promo'>
                                                      <div className='promo-headings'>
                                                            <h2>eBook + EDU</h2>
                                                            <span>per 6 mesi</span>
                                                      </div>
                                                      <div className='promo-chip'>
                                                            <span>Include un manuale</span>
                                                      </div>
                                                </div>
                                                <div className='pricing'>
                                                      <h2>€ 6,50 /mese</h2>
                                                      <h3>Pagamento unico di € 39,00</h3>
                                                </div>
                                                <div className='action'>
                                                      <Link to='#' className='gradient-button align-self-center button-disabled'>Scegli il libro</Link>
                                                </div>
                                                <div className='list'>
                                                      <h4>Include:</h4>
                                                      <div className='list-items'>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <span>1 manuale Giappichelli in versione eBook</span>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <div>
                                                                        <span>Accesso illimitato a tutte le risorse didattiche EDU</span>
                                                                  </div>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <span>Prepara 6 esami di giurisprudenza</span>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <span className='bold'>Possibilità di cambio libro gratuito</span>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div className='edu-info'>
                        <p>Pagamento sicuro. Offerta valida per il primo semestre, rinnovo automatico allo scadere dei 6 mesi.<br />Disdici quando vuoi</p>
                  </div>
                  <div className='edu-faq'>
                        <div>
                              <h1>Informazioni</h1>
                              <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                          <Accordion.Header>Cos'è Giappichelli EDU?</Accordion.Header>
                                          <Accordion.Body>
                                                <p>
                                                      Giappichelli EDU è la piattaforma digitale di studio di prossima generazione in cui puoi trovare diversi strumenti e contenuti di qualità per migliorare la tua preparazione e comprensione del diritto:
                                                      casi studio, quiz di autovalutazione, mappe concettuali e molto altro.
                                                </p>
                                          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                          <Accordion.Header>Che metodi di pagamento posso usare?</Accordion.Header>
                                          <Accordion.Body>
                                                <p>
                                                      Accettiamo tutte le carte di credito e debito supportate da Stripe.<br/>
                                                      Attualmente non supportiamo il pagamento con Bonus Cultura
                                                </p>
                                          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                          <Accordion.Header>Come funziona l'abbonamento?</Accordion.Header>
                                          <Accordion.Body>
                                                <p>
                                                      L'abbonamento ha una durata minima di 6 mesi. è sempre possibile annullare la ricorrenza di pagamento oltre i 6 mesi vincolanti.<br/>
                                                      Dopo la decorrenza di 6 mesi, l'addebito mensile può essere annullato dalla gestione dei <Link to="/my-products/">tuoi acquisti</Link>, cliccando sul tasto gestione del tuo abbonamento EDU puoi procedere alla cancellazione degli addebiti successivi.
                                                </p>
                                          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                          <Accordion.Header>Che materie sono incluse nell'abbonamento?</Accordion.Header>
                                          <Accordion.Body>
                                                <p>
                                                      EDU è in costante espansione. Attualmente offre centinaia di risorse didattiche per ognuna delle seguenti materie giuridiche: Diritto pubblico, Diritto costituzionale, Diritto privato, Diritto penale, Diritto del lavoro, Diritto amministrativo.
                                                </p>
                                          </Accordion.Body>
                                    </Accordion.Item>
                              </Accordion>
                        </div>


                  </div>
            </section>
      );
}
