import './password.css';
import AppContext from '../../AppContext';
import { useAuth } from 'react-oidc-context';
//import { useNavigate } from 'react-router-dom';
import { /*useEffect,*/useState,useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { notifyWarn,notifySuccess } from '../utilities/toaster';
import {uscall} from '../../us';

function ForgotPassword () {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);// used to raise warnings
    const [isSubmitting, setIsSubmitting] = useState(false);// used to avoid double submissions

    const auth = useAuth();
    const appcontext = useContext(AppContext);
    //let navigate = useNavigate();
    //useEffect(() => {
    //  if(auth.isAuthenticated){
    //    navigate('/');
    //  }
    //},[auth,navigate]);


    const checkMail=function(v){
	return (v.trim().length>0 && v.trim().match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
	// https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
    };

    const doRecover=function(){
	return new Promise(async function(resolve,reject){
		try{
			const r=await uscall(appcontext,auth,'aaa','passwordreset','request',null,{username:email});
			if(typeof(r['error'])!='undefined')resolve(null);
			else if(typeof(r['result'])!='undefined' && r.result);
			else resolve(null);

			resolve(r.result);
		}catch(err){
			reject(err);
		}
	});
    }
    const handleSubmit = (e) => {
        if(e)e.preventDefault();
	if(isSubmitting)return;

        setIsSubmitted(true);
	if(checkMail(email));else return;
        doRecover().then(function(isok){
        	setIsSubmitting(false);
		if(isok){
        		setIsSubmitted(false);
			notifySuccess('Richiesta inviata, controlla la tua casella email');
		}else{
        		setIsSubmitted(false);
            		notifyWarn(
            		    process.env.PUBLIC_URL+'alert.svg',
            		    'Email non trovata',
            		    <>L'email con cui stai cercando di registrati non è presente nel nostro database.</>,
            		);
		}
	},function(err){
        	setIsSubmitting(false);
        	setIsSubmitted(false);
            	notifyWarn(
            	    process.env.PUBLIC_URL+'alert.svg',
            	    'Errore di comunicazione',
            	    <>Ti preghiamo di riprovare più tardi</>,
            	);
	});
    };

    return(

        <div className='page password'>
            <div className='row h-100 g-0'>
                <div className='col-6 page-background'></div>
                <div className='col-sm-5 col-md-6 col-lg-5 col-xl-6 page-right'>
                    <h1>Password dimenticata</h1>
                    <p>Non preoccuparti, ti inviamo le istruzioni per aggiornare la tua password.<br />
                    Inserisci l'email con cui ti sei registrato.
                    </p>
                    <p>Se non utilizzi più l'indirizzo email associato all'account Giappichelli potrai<br/>
                    contattare il Servizio Clienti per ricevere assistenza sul ripristino<br />
                    dell'accesso all'account.</p>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label for="email" className='form-label'>Email *</label>
                            <input 
                            type='email' 
                            id="email" 
                            placeholder='Email' 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required={isSubmitted && !checkMail(email)}
				        />
                        </div>
                        <div className='text-center'>
                            <p>Non hai ricevuto la mail? <span onClick={handleSubmit}>Rinvia</span></p>
                        </div>
                        <div>
                            <button className='gradient-button' type="submit">Invia</button>
                        </div>
                    </form>
                    <ToastContainer className="custom-toast custom-toast-warning" />
                </div>
            </div>
        </div>

    )

}

export default ForgotPassword;
