import './myproducts.css';
import Payment from './payment';

const PurchaseHistory = ({orders,producttypes}) => {

    return (
        <>
            <h2 className='m-0'>Storico acquisti</h2>
            <div className='purchase-history'>
		{orders?(<>{orders.map(order=>{return (<Payment key={order.uuid?order.uuid:order.magento_id} order={order} producttypes={producttypes} />);})}</>):null}
                {/*<button className='gradient-button reset-button'><span>Carica successivi</span></button>*/}
            </div>
        </>
    )

}

export default PurchaseHistory;
