import './header.css';
import './headerDash.css';
import HeaderMenu from '../../utilities/headerMenu';
import { useLocation } from 'react-router-dom';

function HeaderComponent() {
    const location = useLocation();
	//<div style={{display:'none'}}> DEBUG TOREMOVE {auth && auth.isAuthenticated ? (<>{auth.user?.profile.email} {auth.user?.profile.given_name} {auth.user?.profile.family_name} {(new Date(auth.user?.expires_at)).toString()} {auth.user?.access_token}</>) : (<>non loggato</>)}</div>
	return(

		<header className={(!['/register/','/register','/forgot-password','/forgot-password/','/profilo/primoaccesso','/profilo/primoaccesso/','/'].includes(location.pathname))?"":"headermini"}>
			<HeaderMenu />
		</header>

	)

}

export default HeaderComponent;
