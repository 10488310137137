import '../wrapper/header/headerDash.css';
import '../wrapper/header/header.css';
import {useState,useEffect,useRef} from 'react';
import { Link,useLocation } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import CustomModal from './modals';
import queryString from 'query-string';

function UserMenu({ open, doLogout, closeMenu }) {
	const location = useLocation();
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [modalType1, setModalType1] = useState('userLogout');
    const [modalType2, setModalType2] = useState('activateProduct');
    const effectRan = useRef(false);// use to avoid in dev mode double rendering after redirect, causing NS_BINDING_ERR: 

    const handleCloseModal1 = () => setShowModal1(false);
    const handleShowModal1 = (type) => {
        setModalType1(type);
        setShowModal1(true);
    };

    const handleCloseModal2 = () => setShowModal2(false);
    const handleShowModal2 = (type) => {
        setModalType2(type);
        setShowModal2(true);
    };

	useEffect(()=>{
		if(!effectRan.current){
		//if(location.pathname==='/dashboard/'){
			const {show}=queryString.parse(location.search);
			if(show && show==='activateProduct'){
				closeMenu();handleShowModal2('activateProduct');
			}
		//}
	  		return () => effectRan.current = true;
		}
	},[closeMenu,location.pathname,location.search]);

    const handleSubmitModal1 = () => {
        doLogout();
        handleCloseModal1();
    };

    const handleSubmitModal2 = () => {
        // TODO logica di attivazione prodotto
        handleCloseModal2();
    };

    const modalContent1 = {
        userLogout: {
            title: 'Disconnessione utente',
            bodyContent: 'Per procedere con il logout, cliccare sul pulsante "Conferma". In caso contrario, cliccare su "Annulla" per rimanere connesso al tuo account Giappichelli',
            bodySubtitle: 'Stai per effettuare il logout dal tuo account. Questa operazione comporterà la chiusura di tutte le sessioni aperte',
        },
    };

    const modalContent2 = {
        activateProduct: {
            icon: `${location.pathname === '/dashboard/' ? `${process.env.PUBLIC_URL}/activate-w.svg` : `${process.env.PUBLIC_URL}/activate.svg`}`,
            title: 'Attiva prodotto',
            bodyContent: '',
            bodySubtitle: 'Dopo aver inserito il codice di attivazione potrai accedere al tuo prodotto digitale e scoprire tutte le funzionalità',
        },
    };


    const handleCloseButtonClick1 = () => {
        handleCloseModal1();
    };

    const handleCloseButtonClick2 = () => {
        handleCloseModal2();
    };

    return (
        <>
            <Collapse in={open}>

                <div id="collapse-menu" className={`${location.pathname === '/dashboard/' ? 'black-menu' : ''}`}>
                    <div className='menu-container'>
                        <div>
                            <ul>
                                <li className={location.pathname==='/profile/' ? 'selected':''}><Link to="/profile/" onClick={()=>{closeMenu();}}>Il mio account</Link></li>
                                <li className={location.pathname==='/my-products/' ? 'selected':''}><Link to="/my-products/" onClick={()=>{closeMenu();}}>I miei acquisti</Link></li>
                                <li className={location.pathname==='/profile/preferences/' ?'selected':''} onClick={()=>{closeMenu();}}><Link to="/profile/preferences/">Preferenze</Link></li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li className={location.pathname==='' ?'selected':''}><span onClick={()=>{closeMenu();handleShowModal2('activateProduct');}}>Attiva prodotto</span></li>
                                <li className={location.pathname==='/help-center/' ?'selected':''}><Link to="/help-center" onClick={()=>{closeMenu();}}>Help Center</Link></li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li className={location.pathname==='/profile/change-password/' ?'selected':''}><Link to="/profile/change-password/" onClick={()=>{closeMenu();}}>Cambio password</Link></li>
                                <li className={location.pathname==='' ?'selected':''}><span onClick={()=>{handleShowModal1('userLogout');}}>Logout</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Collapse>

            {/* Modali */}
            <CustomModal
                modalType={modalType1}
                showModal={showModal1}
                handleClose={handleCloseModal1}
                handleSubmit={handleSubmitModal1}
                title={modalContent1[modalType1].title}
                bodyContent={modalContent1[modalType1].bodyContent}
                bodySubtitle={modalContent1[modalType1].bodySubtitle}
                closeButtonAction={handleCloseButtonClick1}
                closeButtonText="Chiudi"
                confirmButtonText="Conferma"
            />

            <CustomModal
                modalType={modalType2}
                showModal={showModal2}
                handleClose={handleCloseModal2}
                handleSubmit={handleSubmitModal2}
                icon={modalContent2[modalType2].icon}
                title={modalContent2[modalType2].title}
                bodyContent={modalContent2[modalType2].bodyContent}
                bodySubtitle={modalContent2[modalType2].bodySubtitle}
                closeButtonAction={handleCloseButtonClick2}
                closeButtonText="Chiudi"
                confirmButtonText="Conferma"
            />

        </>
    );
};

export default UserMenu;
