import './codeunlock.css';
import { Link,useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import contentData from './ContentData';
import { useAuth } from 'react-oidc-context';

function CodeUnlock({contentpage}) {
    const content = contentData[contentpage] || contentData.default;
    const auth = useAuth();
    const isAuthenticated = auth.isAuthenticated;
    let navigate = useNavigate();

	const doLogin=function(){
		navigate('/login/?redirect='+encodeURIComponent(document.location.href));
	};
	const doRegister=function(){
		navigate('/register/?redirect='+encodeURIComponent(document.location.href));
	};

    return (
        <div className='page'>
            <div className='row h-100 g-0'>
                <div className='col-6 page-background' style={{ backgroundImage: content.backgroundImage }}></div>
                <div className='col-sm-5 col-md-6 col-lg-5 col-xl-6 page-right codeunlock'>
                    <div className='headings'>
                        <h1>{content.mainTitle}</h1>
                        <h2>{content.subTitle}</h2>
                    </div>
                    <hr />
                    <div className='contents bottom-info'>
			{content.subTitle2?(<>
                            <h4>{content.subTitle2}</h4>
                            <h4>{content.subTitle3}</h4>
			</>):(<>
			{content.listTitle?(<>
                        <div className='list'>
                            <h4>{content.listTitle}</h4>
                            <div className='list-items'>
                                {content.listItems.map((item, index) => (
                                    <div key={index} className='list-item'>
                                        <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} alt='list-icon'/>
                                        <div>
                                            {item.parts.map((part, i) => (
                                                <span key={i} className={part.type === 'bold' ? 'bold' : ''}>
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div></>):(<></>)}</>)}
                        <hr />
                        <div className="row w-100 buttons-container">
                            {isAuthenticated ? (
                                <div className="col">
                                    <a className='gradient-button' href="?show=activateProduct">Attiva prodotto</a>
                                </div>
                            ) : (
                                <>
                                    <div className="col-md-6">
                                        <button type="button" className='gradient-button reset-button' onClick={doLogin} style={{width:'100%'}}>Accedi</button>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="button" className='gradient-button' onClick={doRegister} style={{width:'100%'}}>Registrati</button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CodeUnlock;
 
