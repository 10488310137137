import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import {useEffect,useState } from 'react';
import AppContext from './AppContext';
import AppRouter from './AppRouter';
import {downloadmanifestfrom} from './us';

function App() {
	const [globalappcontext,setGlobalAppContext]=useState(null);
	const [oidcConfig, setOidcConfig] = useState(null);
	useEffect(() => {
		const cb=async function(){
			let c=await fetch(process.env.REACT_APP_AUTHORITY+'/api/manifest.json');
			let r=await c.json();
			let mainmanifest={};
			mainmanifest[r.code]=r;
			const newconfig={
				manifest:r,
				manifests:await downloadmanifestfrom(mainmanifest),
				client_id:'aaafrontendui',
				oidcconfig:{
		  			onSigninCallback: async function(user){
						document.location.href = (user && user.state && user.state.url?user.state.url:'/');// redirect
					},
					authority: process.env.REACT_APP_AUTHORITY+'/',
					client_id: process.env.REACT_APP_CLIENT_ID,
					redirect_uri: document.location.origin + '/oauthcb',
					userStore: new WebStorageStateStore({ store: window.localStorage}),
					scope: "openid email profile"
				}
			};
			setGlobalAppContext(newconfig);
			setOidcConfig(newconfig.oidcconfig);
		};
		if(globalappcontext);else
			cb();
	},[globalappcontext,oidcConfig]);

	return (
    <AppContext.Provider value={globalappcontext}>
      {oidcConfig?(
      <AuthProvider {...oidcConfig}>
				{
					oidcConfig?(<AppRouter />
					):(<div>Initializing...</div>)
				}
      </AuthProvider>
      ):(<></>)}
    </AppContext.Provider>
	);
}

export default App;
