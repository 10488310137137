import ActivateLanding from './activate-landing';
import BannerLanding from './banner-landing';
import BoxLanding from './box-landing';
import CardsLanding from './cards-landing';
import CollaborateLanding from './collaborate-landing';
import './landing.css';
import NewsLanding from './news-landing';

function Landing({ landingPage }) {
    return (
        <>
            <BannerLanding landingPage={landingPage} />
            {landingPage !== 'biblioteca-digitale' && <CardsLanding />}
            {landingPage !== 'biblioteca-digitale' && (
                <div className='divider-landing'>
                    <hr />
                    <div>
                        <a href="#">Scopri tutte le novità <img src={`${process.env.PUBLIC_URL}/icon-arrow-blue.svg`} alt="Scopri tutte le novità" /></a>
                    </div>
                    <hr />
                </div>
            )}
            {landingPage !== 'edu' && <NewsLanding landingPage={landingPage} />}
            {landingPage !== 'professionali' && <ActivateLanding landingPage={landingPage} />}
            <CollaborateLanding landingPage={landingPage}/>
        </>
    );
}

export default Landing;
